<template>
  <modal name="select-local-office-modal" @closed="closeSelectNewLocalOffice" :scrollable="true" :width="1200"
         :height="670" :maxHeight="600">
    <h4>Sök postnummer - välj kontor</h4>

    <div class="mt-2">
      <reactive-base
          app="postal_code_index"
          :credentials="user.user.es_username + ':' + user.user.es_password"
          :url="elasticUrl"
      >
        <div class="widget widget-modal">
          <div class="widget-header">
            <div class="widget-header-toolbar">
              <div class="actions">
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.police-region')"
                      componentId="policeFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :showSearch="true"
                      sortBy="asc"
               
                      :react="{ and: ['search', 'postalCode', 'countyFilter', 'municipalityFilter', 'officeFilter'] }"
                      :filterLabel="this.$t('labels.police-region')"
                      dataField="police_region.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.county')"
                      componentId="countyFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      :size="100"
                      :showSearch="true"
                      sortBy="asc"
                 
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'municipalityFilter', 'officeFilter'] }"
                      :filterLabel="this.$t('labels.county')"
                      dataField="county.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.municipality')"
                      componentId="municipalityFilter"
                      :URLParams="true"
                      fuzziness="AUTO"
                      sortBy="asc"
                      :showSearch="true"
                      :size="1000"
                    
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'officeFilter'] }"
                      :filterLabel="this.$t('labels.municipality')"
                      dataField="municipality.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="filter-action ml-3">
                  <multi-dropdown-list
                      :placeholder="this.$t('labels.victim-support-centre')"
                      componentId="officeFilter"
                      :URLParams="true"
                      sortBy="asc"
                      fuzziness="AUTO"
                      :showSearch="true"
                      :size="100"
                      nestedField="local_offices"
                      :react="{ and: ['search', 'postalCode', 'policeFilter', 'countyFilter', 'municipalityFilter'] }"
                      :filterLabel="this.$t('labels.victim-support-centres')"
                      dataField="local_offices.office.raw"
                  ></multi-dropdown-list>
                </div>
                <div class="search-action ml-3">
                  <data-search
                      componentId="postalCode"
                      dataField="postal_code"
                 
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :URLParams="true"
                      :fuzziness="0"
                      :debounce="100"
                      :placeholder="this.$t('labels.postal-code') + '..'"
                      :filterLabel="this.$t('labels.search')"
                      :autosuggest="false"
                      
                  />
                </div>
                <div class="search-action ml-3">
                  <data-search
                      componentId="search"
                      :dataField="[
											'postal_code',
                      'municipality',
                      'police_region',
                      'post_town',
											'county',
										]"
                      iconPosition="right"
                      className="data-search"
                      :showClear="true"
                      :highlight="true"
                      :URLParams="true"
                      :fuzziness="2"
                      :placeholder="this.$t('labels.search') + '..'"
                      :debounce="100"
                      :filterLabel="this.$t('labels.search')"
                      :autosuggest="false"
                      
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="widget-body no-padding">
            <selected-filters class="bb-1 curent-filters pl-4" :clearAllLabel="this.$t('labels.clear')"/>
          </div>
          <div class="widget-body no-padding">
            <reactive-list
                :showResultStats="false"
                componentId="SearchResult"
                className="result-list-container"
                :pagination="true"
                :dataField="getSortBy"
                :sortBy="getSortByOrder ? 'desc' : 'asc'"
                :URLParams="true"
                :from="0"
                :size="15"
                :react="{ and: ['search', 'postalCode', 'policeFilter', 'municipalityFilter', 'officeFilter'] }"
            >
              <table class="w-100 vuetable" slot="render" slot-scope="{ loading, error, data }">
                <thead>
                <tr>
                  <th
                      width="20%"
                  >
                    {{ $t('labels.victim-support-centre') }}
                  </th>
                  <th
                      width="20%"
                  >
                    {{ $t('labels.local-office') }}
                  </th>
                  <th
                      width="20%"
    
                  >
                    {{ $t('labels.municipality') }}
                  </th>
                  <th
                      width="16%"
                  >
                    {{ $t('labels.place') }}
                  </th>
                     <th
                      width="16%"
                  >
                    {{ $t('labels.postal-code') }}
                  </th>
                  <th width="10%"></th>
                </tr>
                </thead>
                <tbody >
                
                <div v-for="item in data" :key="item.id">
                    <tr>
                      <div v-for="local_office in item.local_offices" :key="local_office.id">
                        <td width="20%" v-html="local_office.office"></td>
                        <td width="20%" v-html="local_office.label"></td>
                        <td width="20%" v-html="item.municipality"></td>
                        <td width="20%" v-html="item.post_town"></td>
                        <td width="20%" v-html="item.postal_code"></td>
                        <td width="16%"></td>
                        <td width="10%" class="text-right pr-4">
                          <button class="btn btn-success" @click="selectLocalOffice(local_office.id, local_office.label)">{{ $t('labels.select') }}</button>
                        </td>
                      </div>
                    </tr>
                </div>


               
                </tbody>
              </table>
            </reactive-list>
          </div>
        </div>
      </reactive-base>
    </div>
  </modal>
</template>
<script>
export default {
  data() {
    return {
      elasticUrl: process.env.VUE_APP_API_ELASTIC,
      getSortByOrder: true,
      getSortBy: '_score',
    };
  },
  mounted() {
    this.$modal.show('select-local-office-modal');
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {

    closeSelectNewLocalOffice() {
      this.$emit('closed');
    },
    isSortField(field, direction) {
      if (this.getSortBy === field && this.getSortByOrder === direction) {
        return true;
      } else {
        return false;
      }
    },
      changeSortBy(value) {
      this.getSortBy = value;
      this.getSortByOrder = !this.getSortByOrder;

      this.updateQueryStringParam('sort', value);
      this.updateQueryStringParam('sortOrder', this.getSortByOrder);
    },

    selectLocalOffice(local_office_id, local_office_label) {
      let regex = /(<([^>]+)>)/ig;
      local_office_label = local_office_label.replace(regex, "");

      this.$modal.hide('select-local-office-modal');
      this.$emit('closed', local_office_id, local_office_label);
    },
    updateQueryStringParam(key, value) {
      const baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
      const urlQueryString = document.location.search;
      const newParam = key + '=' + value;
      let params = '?' + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        const keyRegex = new RegExp('([?&])' + key + '[^&]*');

        // If param exists already, update it - otherwise, add it to end of query string
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, '$1' + newParam);
        } else {
          params = urlQueryString + '&' + newParam;
        }
      }
      history.pushState({}, '', baseUrl + params);
    },
  },
};
</script>
